import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import Layout from '../../components/layout';
import Overview from '../../components/overview';

const hydrationPct = 0.63;
const saltPct = 0.02;
const maltPct = 0.02;
const oilPct = 0.02;
const levainPct = 0.2;

function ouncesToGrams(ounces) {
  return ounces * 28.3495;
}

function sizeToDBW(diameter, tf = 0.1) {
  const radius = diameter / 2;
  return ouncesToGrams(Math.PI * Math.pow(radius, 2) * tf);
}

function scaleRecipe(doughWeight) {
  const flour =
    doughWeight / (saltPct + maltPct + oilPct + levainPct + hydrationPct + 1);
  const levain = levainPct * flour;

  return {
    levainFlour: _.round(levain / 2),
    levainWater: _.round(levain / 2),
    levainStarter: _.round(0.2 * levain),
    doughFlour: _.round(flour),
    doughWater: _.round(hydrationPct * flour),
    doughLevain: _.round(levain),
    doughSalt: _.round(saltPct * flour),
    doughOil: _.round(oilPct * flour),
    doughMalt: _.round(maltPct * flour)
  };
}

const Form = styled.form`
  margin-top: 1rem;
`;

export default ({ data }) => {
  const [numDoughBalls, setNumDoughBalls] = React.useState(4);
  const [pizzaSize, setPizzaSize] = React.useState(10);

  const doughWeight = sizeToDBW(pizzaSize) * numDoughBalls;
  const recipe = scaleRecipe(doughWeight);

  return (
    <Layout>
      <Helmet>
        <title>Home Oven Sourdough Pizza</title>
      </Helmet>
      <div>
        <h1>Home Oven Sourdough Pizza</h1>
        <Img fluid={data.file.childImageSharp.fluid} />
        <Overview>
          This recipe is for people who want to make NY style pizza in a home
          oven. For best results, a baking steel or stone is recommended to cook
          the pizza on, but if you don't have one don't worry, a pan will do.
          While the recipe seems long and involved, much of the time is idle
          time, waiting for things to happen. The actual work involved can
          easily be incorporated into a busy day.
        </Overview>
        <Form>
          <div className="columns">
            <div className="column">
              <label className="label" htmlFor="numDoughBalls">
                What size pizzas would you like to make?
              </label>
              <div className="field">
                <div className="control">
                  <div className="select">
                    <select
                      value={pizzaSize}
                      onChange={e => setPizzaSize(e.target.value)}
                      onBlur={e => setPizzaSize(e.target.value)}
                    >
                      <option value={10}>Small (10")</option>
                      <option value={12}>Medium (12")</option>
                      <option value={14}>Large (14")</option>
                      <option value={16}>Extra Large (16")</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <label className="label" htmlFor="numDoughBalls">
                How many pizzas would you like to make?
              </label>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id="numDoughBalls"
                    name="numDoughBalls"
                    value={numDoughBalls}
                    onChange={e => setNumDoughBalls(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <h2>Levain</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Flour</td>
              <td>{recipe.levainFlour} g</td>
            </tr>
            <tr>
              <td>Water</td>
              <td>{recipe.levainWater} g</td>
            </tr>
            <tr>
              <td>Active Starter</td>
              <td>{recipe.levainStarter} g</td>
            </tr>
          </tbody>
        </table>
        <h2>Dough</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Bakers %</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Flour</td>
              <td>100%</td>
              <td>{recipe.doughFlour} g</td>
            </tr>
            <tr>
              <td>Water</td>
              <td>{hydrationPct * 100}%</td>
              <td>{recipe.doughWater} g</td>
            </tr>
            <tr>
              <td>Levain</td>
              <td>{levainPct * 100}%</td>
              <td>{recipe.doughLevain} g</td>
            </tr>
            <tr>
              <td>Salt</td>
              <td>{saltPct * 100}%</td>
              <td>{recipe.doughSalt} g</td>
            </tr>
            <tr>
              <td>Diastatic Malt Powder or Honey</td>
              <td>{maltPct * 100}%</td>
              <td>{recipe.doughMalt} g</td>
            </tr>
            <tr>
              <td>Oil</td>
              <td>{oilPct * 100}%</td>
              <td>{recipe.doughOil} g</td>
            </tr>
          </tbody>
        </table>
        <h1>Useful (but not necessary) Tools</h1>
        <ul>
          <li>
            <a href="https://shop.bakingsteel.com/collections/steels/products/baking-steel">
              Baking Steel
            </a>{' '}
            or Baking Stone
          </li>
          <li>Stand Mixer</li>
          <li>Bowl Scraper</li>
          <li>Bench Scraper</li>
          <li>Scale (this is pretty necessary)</li>
        </ul>
        <h1>Ingredient Considerations</h1>
        <h2>Flour</h2>
        <p>
          Ideally you want to use a strong, high-protein flour for making NY
          style pizza. For many home cooks the easiest to find option is King
          Arthur Bread flour. Most NY style pizza shops use a commercial flour
          like General Mills All Trumps. If you have access to it, by all means
          use it, but make sure to buy the version without potassium bromate as
          it is classified as possibly carcinogenic to humans. I prefer organic
          flours from Central Milling such as <strong>High Mountain</strong>.
          Other good options include King Arthur Sir Lancelot and Pendleton
          Power flour.
        </p>
        <h2>Water</h2>
        <p>
          While it is by no means necessary, I like to use dechlorinated water.
          If you live in a place that treats water with chlorine, simply letting
          it off-gas in an open container overnight will eliminate most of the
          chlorine. Filtration and boiling will also eliminate chlorine more
          rapidly. If your local water is treated with Chloramine, the
          aforementioned methods will not work, but it can be removed with
          Campden Tablets (potassium metabisulfite). Use caution when handling
          potassium metabisulfite and ensure that it is dosed properly. If you
          don't want to go through the hassle of dechlorinating your water, tap
          water works perfectly fine.
        </p>
        <h2>Malt Powder</h2>
        <p>
          If using diastatic malt powder, it is worth noting its degrees
          Lintner. Most are around 60 degrees but some are >200 degrees. This is
          a measure of the enzymatic activity of the malt powder, and if too
          high can lead to faster fermentations and other problems with the
          crumb of the pizza. This recipe is formulated for 60 degrees Lintner
          malt powder.
        </p>
        <h2>Oil</h2>
        <p>
          You can use Olive Oil or a flavor neutral oil such as Corn Oil
          depending on your taste preference.
        </p>
        <h2>Salt</h2>
        <p>Any non-iodized salt should work fine, no need to get too fancy.</p>
        <h1>Schedule</h1>
        Below is a mock schedule for making the dough, feel free to adjust it to
        your own schedule. Feel free to play around with times and temperatures
        to better suit it to your own needs.
        <h2>Day 1</h2>
        <h3>
          <i>9:00 am</i>
        </h3>
        <p>
          Begin by mixing the ingredients together for the levain. Mix until
          there are no dry spots and everything is well incorporated. Cover and
          place in a warm spot, such as an oven with the light on or on top of
          your fridge, until doubled in volume and bubbly, 4-8 hours.
        </p>
        <h3>
          <i>5:00 pm</i>
        </h3>
        <p>
          Combine the flour, malt powder, oil, water, and levain in the bowl of
          a stand mixer. If you do not have a stand mixer, mixing by hand works
          great as well. Mix on low speed until there are no dry spots and a
          shaggy dough is formed, 1-2 minutes. Cover with a damp towel and let
          rest in a warm place for 20 minutes.
        </p>
        <h3>
          <i>5:20 pm</i>
        </h3>
        <p>
          Add salt to dough and mix on low speed for 1 minute and medium speed
          for 4-6 minutes until dough is smooth. If kneading by hand, knead for
          at least 10 minutes. Form dough into a rough ball, scrape down the
          bowl, cover with a damp towel, and allow to bulk ferment in a warm
          spot for 4 hours.
        </p>
        <h3>
          <i>9:20 pm</i>
        </h3>
        <p>
          Scrape dough out of the bowl and weigh it. Divide dough into{' '}
          {numDoughBalls} equal pieces. Form each piece into a ball by folding
          all four corners into the center, then fold any remaining dough into
          the center. Pinch to seal, flip over so the seam is on the bottom and
          round the dough out on the counter. Place balls into an oiled tray and
          cover with plastic wrap. Refrigerate for 19 hours or up to 2 days.
        </p>
        <h2>Day 2</h2>
        <h3>
          <i>5:00 pm</i>
        </h3>
        <p>
          Preheat oven to its highest setting, preferably 500 degrees or more.
          If you have a baking steel or stone, preheat it for at least 45
          minutes. While you will get fine results using a pan, a baking steel
          will help with browning and achieving a crispier crust.
        </p>
        <h3>
          <i>6:00 pm</i>
        </h3>
        <p>
          Dust a pizza peel with fine Semolina or flour. Dust your counter
          generously with flour. Remove a dough ball directly from the fridge
          and place into the flour on the counter. Shape the ball into a pizza
          using a technique like{' '}
          <a href="https://www.youtube.com/watch?v=SjYqw1CLZsA">this</a>. Move
          the stretched dough onto the peel.
        </p>
        <p>
          Top the dough with sauce, cheese, and toppings and slide it into the
          oven onto your baking steel or stone. Bake for ~10 minutes, or until
          cheese has browned and crust is browned and bubbly.
        </p>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "recipes/homeOvenPizza/main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 300, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
